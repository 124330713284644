<!-- Right Sidebar Start -->
<div class="end-bar" (clickOutside)="hide()">
    <!-- Title -->
    <div class="rightbar-title">
        <a href="javascript:void(0);" class="end-bar-toggle float-end" (click)="hide()">
            <i class="dripicons-cross noti-icon"></i>
        </a>
        <h5 class="m-0">Settings</h5>
    </div>

    <!-- Sidebar -->
    <ngx-simplebar class="rightbar-content h-100">

        <div class="p-3">
            <ngb-alert [dismissible]="false" type="warning">
                <strong>Customize </strong> the overall color scheme, layout width, etc.
            </ngb-alert>


            <!-- Layout Settings -->
            <h5 class="mt-3">Layout</h5>
            <hr class="mt-1" />
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="layout" value="vertical" id="vertical-check"
                    [(ngModel)]="layoutType" (change)="changeLayout('vertical')" />
                <label class="form-check-label" for="vertical-check">Vertical Layout (Default)</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="layout" value="horizontal" id="horizontal-check"
                    [(ngModel)]="layoutType" (change)="changeLayout('horizontal')" />
                <label class="form-check-label" for="horizontal-check">Horizontal Layout</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="layout" value="detached" id="detached-check"
                    [(ngModel)]="layoutType" (change)="changeLayout('detached')" />
                <label class="form-check-label" for="detached-check">Detached Layout</label>
            </div>

            <!-- Width Settings-->
            <h5 class="mt-4">Width</h5>
            <hr class="mt-1" />
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="width" value="fluid" id="fluid-check"
                    [(ngModel)]="layoutWidth" (change)="changeLayoutWidth('fluid')" [disabled]="disableLayoutWidth" />
                <label class="form-check-label" for="fluid-check">Fluid</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="width" value="boxed" id="boxed-check"
                    [(ngModel)]="layoutWidth" (change)="changeLayoutWidth('boxed')" [disabled]="disableLayoutWidth" />
                <label class="form-check-label" for="boxed-check">Boxed</label>
            </div>

            <!-- Sidebar Settings-->
            <h5 class="mt-4">Left Sidebar</h5>
            <hr class="mt-1" />
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="theme" value="default" id="default-check"
                    [(ngModel)]="leftSidebarTheme" (change)="changeLeftSidebarTheme('default')"
                    [disabled]="disableSidebarTheme" />
                <label class="form-check-label" for="default-check">Default</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="theme" value="light" id="light-check"
                    [(ngModel)]="leftSidebarTheme" (change)="changeLeftSidebarTheme('light')"
                    [disabled]="disableSidebarTheme" />
                <label class="form-check-label" for="light-check">Light</label>
            </div>
            <div class="form-check form-switch mb-3">
                <input type="radio" class="form-check-input" name="theme" value="dark" id="dark-check"
                    [(ngModel)]="leftSidebarTheme" (change)="changeLeftSidebarTheme('dark')"
                    [disabled]="disableSidebarTheme" />
                <label class="form-check-label" for="dark-check">Dark</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="compact" value="fixed" id="fixed-check"
                    [(ngModel)]="leftSidebarWidth" (change)="changeLeftSidebarType('fixed')"
                    [disabled]="disableSidebarType" />
                <label class="form-check-label" for="fixed-check">Fixed</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="compact" value="condensed" id="condensed-check"
                    [(ngModel)]="leftSidebarWidth" (change)="changeLeftSidebarType('condensed')"
                    [disabled]="disableSidebarType" />
                <label class="form-check-label" for="condensed-check">Condensed</label>
            </div>
            <div class="form-check form-switch mb-1">
                <input type="radio" class="form-check-input" name="compact" value="scrollable" id="scrollable-check"
                    [(ngModel)]="leftSidebarWidth" (change)="changeLeftSidebarType('scrollable')"
                    [disabled]="disableSidebarType" />
                <label class="form-check-label" for="scrollable-check">Scrollable</label>
            </div>


            <!-- Reset to Default -->
            <div class="d-grid mt-4">
                <button class="btn btn-primary" id="resetBtn" (click)="reset()">Reset to Default</button>
            </div>
        </div>

    </ngx-simplebar>

</div>
<div class="rightbar-overlay"></div>
<!-- Right Sidebar End -->