<!-- Vertical Layout -->
<app-vertical-layout [isBoxed]="isBoxedRequested()" [sidebarTheme]="leftSidebarTheme" [sidebarType]="leftSidebarWidth"
    *ngIf="isVerticalLayoutRequested()"></app-vertical-layout>

<!-- Horizontal Layout -->
<app-horizontal-layout *ngIf="isHorizontalLayoutRequested()" [isBoxed]="isBoxedRequested()"
    [configuredDemo]="configuredDemo"></app-horizontal-layout>

<!-- Detached Layout -->
<app-detached-layout *ngIf="isDetachedLayoutRequested()" [sidebarType]="leftSidebarWidth"></app-detached-layout>

<!-- Right sidebar -->
<app-right-side-bar [layoutType]="layoutType" [layoutWidth]="layoutWidth" [leftSidebarTheme]="leftSidebarTheme"
    [leftSidebarWidth]="leftSidebarWidth"></app-right-side-bar>