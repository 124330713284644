<div class="mt-5 mb-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-xl-10">

                <div class="text-center">
                    <img src="assets/images/maintenance.svg" height="140" alt="File not found Image">
                    <h3 class="mt-4">Site is Under Maintenance</h3>
                    <p class="text-muted">We're making the system more awesome. We'll be back shortly.</p>

                    <div class="row mt-5">
                        <div class="col-md-4" *ngFor="let query of maintenanceQuery">
                            <div class="text-center mt-3 ps-1 pe-1">
                                <i class="{{query.icon}} bg-primary maintenance-icon text-white mb-2"></i>
                                <h5 class="text-uppercase">{{query.question}}</h5>
                                <p class="text-muted">{{query.answer}}</p>
                            </div>
                        </div> <!-- end col-->

                    </div> <!-- end row-->
                </div> <!-- end /.text-center-->

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
    2018 - 2021 © Hyper - Coderthemes.com
</footer>