<div class="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <div class="card">
                    <!-- Logo -->
                    <div class="card-header pt-4 pb-4 text-center bg-primary">
                        <a (click)="sendToHome()">
                            <span><img src="assets/images/logo.png" alt="" height="18"></span>
                        </a>
                    </div>

                    <div class="card-body p-4">

                        <div class="text-center">
                            <img src="assets/images/startman.svg" height="120" alt="File not found Image">

                            <h1 class="text-error mt-4">500</h1>
                            <h4 class="text-uppercase text-danger mt-3">Internal Server Error</h4>
                            <p class="text-muted mt-3">Why not try refreshing your page? or you can contact <a href=""
                                    class="text-muted"><b>Support</b></a></p>

                            <a class="btn btn-info mt-3" (click)="sendToHome()"><i class="mdi mdi-reply"></i> Return
                                Home</a>
                        </div>

                    </div> <!-- end card-body-->
                </div>
                <!-- end card-->

            </div> <!-- end col -->
        </div>
        <!-- end row -->
    </div>
    <!-- end container -->
</div>
<!-- end page -->

<footer class="footer footer-alt">
    2018 - 2021 © Hyper - Coderthemes.com
</footer>